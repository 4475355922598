exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.48.46.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ParallaxContentBlock__Content_content{align-items:center;display:flex;flex-direction:column;justify-content:center;text-align:center;width:100%}.ParallaxContentBlock__Content_content .ParallaxContentBlock__Content_default-icon .btn-icon:before{background-color:#fff!important;opacity:.1}.ParallaxContentBlock__Content_content-left{align-items:flex-start;text-align:left}.ParallaxContentBlock__Content_content-right{align-items:flex-end;text-align:right}.ParallaxContentBlock__Content_content-btn-margin{margin:16px 0}", ""]);

// exports
exports.locals = {
	"content": "ParallaxContentBlock__Content_content",
	"default-icon": "ParallaxContentBlock__Content_default-icon",
	"defaultIcon": "ParallaxContentBlock__Content_default-icon",
	"content-left": "ParallaxContentBlock__Content_content-left",
	"contentLeft": "ParallaxContentBlock__Content_content-left",
	"content-right": "ParallaxContentBlock__Content_content-right",
	"contentRight": "ParallaxContentBlock__Content_content-right",
	"content-btn-margin": "ParallaxContentBlock__Content_content-btn-margin",
	"contentBtnMargin": "ParallaxContentBlock__Content_content-btn-margin"
};
import React from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'sana/utils';
import styles from './Text.module.scss';

const Text = ({ text, color }) => {
  
  return color ? (
    <div className={styles.htmlWrapper} style={{ color }}>
      {renderHTML(text)}
    </div>
  ) : (
      <div>
        {renderHTML(text)}
      </div>
    );
};

Text.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

export default Text;

exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.48.46.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ParallaxContentBlock__Parallax_parallax-container{height:100%;min-height:inherit}.ParallaxContentBlock__Parallax_parallax-wrapper{display:flex;flex:1 1;padding:0 25px}.ParallaxContentBlock__Parallax_vd-min-height{background-color:var(--adk-theme-placeholderColor);height:26px;position:absolute;width:100%;z-index:-1}.ParallaxContentBlock__Parallax_visually-hidden{clip:rect(0,0,0,0);border:0;height:0;margin:-1px;overflow:hidden;padding:0;position:absolute;width:0}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ParallaxContentBlock__Parallax_parallax-wrapper:after{content:\"\";min-height:inherit}}", ""]);

// exports
exports.locals = {
	"parallax-container": "ParallaxContentBlock__Parallax_parallax-container",
	"parallaxContainer": "ParallaxContentBlock__Parallax_parallax-container",
	"parallax-wrapper": "ParallaxContentBlock__Parallax_parallax-wrapper",
	"parallaxWrapper": "ParallaxContentBlock__Parallax_parallax-wrapper",
	"vd-min-height": "ParallaxContentBlock__Parallax_vd-min-height",
	"vdMinHeight": "ParallaxContentBlock__Parallax_vd-min-height",
	"visually-hidden": "ParallaxContentBlock__Parallax_visually-hidden",
	"visuallyHidden": "ParallaxContentBlock__Parallax_visually-hidden"
};